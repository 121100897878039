import React, { ChangeEventHandler, FocusEventHandler, useState } from "react";
import { Eye, EyeOff } from "react-feather";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Button from "../Button";
import "./style.scss";

interface InputProps {
  type: "text" | "email" | "password" | "textarea" | "search" | "tel" | "number";
  value?: string | number;
  state?: "error" | "disabled" | "";
  onChange?: ChangeEventHandler;
  onBlur?: FocusEventHandler;
  name?: string;
  id?: string;
  placeholder?: string;
  disabled?: boolean;
  helperText?: string | JSX.Element;
  className?: string;
  maxLength?: number;
  minLength?: number;
}

const Input: React.FC<InputProps> = ({
  type,
  value,
  onChange,
  name,
  id,
  placeholder,
  disabled,
  helperText,
  state,
  className,
  minLength,
  maxLength,
  onBlur,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const getClassName = () => {
    if (state === "error") {
      return "input-error"; // Return 'input-error' if state is 'error'
    } else if (state === "disabled") {
      return "input-disabled"; // Return 'input-disabled' if state is 'disabled'
    } else {
      return ""; // Return an empty string if state is neither 'error' nor 'disabled'
    }
  };

  const ViewType = (type: string) => {
    switch (type) {
      case "textarea":
        return (
          <textarea
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            id={id}
            className={`input-textarea ${getClassName()} ${className ?? ""}`}
            placeholder={placeholder}
            autoComplete="off"
            autoCapitalize="off"
            aria-autocomplete="none"
          />
        );
      case "tel":
        return (
          <PhoneInput
            international
            value={value ? value.toString() : ""}
            onChange={() => {
              if (onChange) {
                // TODO: Fix the type error
                // onChange(value as number)
              }
            }}
            name={name}
            id={id}
            disabled={disabled}
            onBlur={onBlur}
            defaultCountry="US"
            className={`input-tel ${getClassName()} ${className ?? ""}`}
            countrySelectProps={{
              // classes: 'country-select',
              showFlags: false,
            }}
            limitMaxLength={true}
          />
        );
      default:
        return (
          <input
            type={showPassword ? "text" : type}
            value={value}
            onChange={onChange}
            name={name}
            id={id}
            placeholder={placeholder}
            disabled={disabled}
            className={
              type === "text" || type === "email" || type === "password" || type === "search"
                ? `input-area ${getClassName()} ${className ?? ""}`
                : ""
            }
            onBlur={onBlur}
            autoComplete="off"
            autoCapitalize="off"
            aria-autocomplete="none"
            minLength={minLength}
            maxLength={maxLength}
          />
        );
    }
  };

  return (
    <>
      {ViewType(type)}

      {/* Extras */}
      {type === "password" && (
        <Button
          type="button"
          className="eye-icon"
          onClick={togglePasswordVisibility}
        >
          {showPassword ? <Eye className="icon" /> : <EyeOff className="icon" />}
        </Button>
      )}
      {helperText && <div className={state === "error" ? "helper-error-text" : ""}>{helperText}</div>}
    </>
  );
};

export default Input;
