// src/notification.tsx
import React from "react";
import { message } from "antd";
import { CheckOutlined, CloseOutlined, ExclamationOutlined, InfoOutlined } from "@ant-design/icons";
import styled, { createGlobalStyle } from "styled-components";
import "./styles.scss";
// Define types for message types
type MessageType = "success" | "error" | "warning" | "info";

interface MessageContainerProps {
  type: MessageType;
}

interface MessageIconProps {
  type: MessageType;
}

const GlobalStyle = createGlobalStyle`
  .ant-message-notice-wrapper {
    text-align: right !important;
    padding: 7px 14px 0 0 !important;
  }

  .ant-message-notice-content {
    padding: 0 !important;
  }
`;

const MessageContainer = styled.div<MessageContainerProps>`
  display: flex;
  align-items: center;
  padding: 25px 30px;
  width: 538px;
  height: 101px;
  background-color: ${({ type }) =>
    type === "success" ? "#E6FAF5" : type === "error" ? "#FDEEEE" : type === "warning" ? "#FDF8E8" : "#EEEEFE"};
  border: 1px solid
    ${({ type }) =>
      type === "success" ? "#00CC99" : type === "error" ? "#EB5757" : type === "warning" ? "#F2C94C" : "#5458F7"};
  border-left: 5px solid
    ${({ type }) =>
      type === "success" ? "#00CC99" : type === "error" ? "#EB5757" : type === "warning" ? "#F2C94C" : "#5458F7"};
`;

const MessageIcon = styled.div<MessageIconProps>`
  margin-right: 22px;
  font-size: 40px;
  color: ${({ type }) =>
    type === "success" ? "#00CC99" : type === "error" ? "#EB5757" : type === "warning" ? "#F2C94C" : "#5458F7"};
`;

interface ShowMessageProps {
  type: MessageType;
  text: string;
}

export const showMessage = ({ type, text }: ShowMessageProps) => {
  message.open({
    content: (
      <>
        <GlobalStyle />
        <MessageContainer type={type}>
          <MessageIcon type={type}>
            {type === "success" && <CheckOutlined />}
            {type === "error" && <CloseOutlined />}
            {type === "warning" && <ExclamationOutlined />}
            {type === "info" && <InfoOutlined />}
          </MessageIcon>
          <div className="notificationWrapper">
            <div className="title">{type.charAt(0).toUpperCase() + type.slice(1)}</div>
            <div className="message">{text}</div>
          </div>
        </MessageContainer>
      </>
    ),
    duration: 3,
  });
};
