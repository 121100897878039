import { baseApi } from "../apiClient";
import {
  ChooseMethod,
  CreateNewPassword,
  Forgot,
  ForgotPassword,
  ForgotPasswordCheck,
  OTPVerification,
  SignIn,
  SignInEmail,
  SignUp,
  ValidateEmail,
  ValidateEmailResend,
} from "./auth.types";
export interface User {
  id?: number;
  authenticated?: boolean;
  uuid?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  type?: string;
  validated?: boolean;
  validationToken?: string;
  validationExpires?: Date;
  birthDate?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  userTypeId?: number;
  forgotPasswordToken?: string;
  forgotPasswordExpires?: Date;
  otpSecret?: string;
  otpVerified?: boolean;
  isOldUser?: boolean;
  bio?: string;
  photo?: string;
  userName?: string;
  position?: string;
  price?: number;
  priceType?: string;
  city?: string;
  country?: string;
  hashtags?: string;
  instagramUrl?: string;
  vimeoUrl?: string;
  tiktokUrl?: string;
  userType?: {
    title?: string;
  };
  isAllowEmailNotification?: boolean;
  isAllowPushNotification?: boolean;
  language?: string;
  secondaryEmail?: string;
  phone?: string;
  isTwoFactorEnabled?: boolean;
  accountStatus?: string;
  accountDisabledReason?: string;
  accountDisabledDate?: Date;
}

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    handleCheck: builder.query<User, void>({
      query: () => "/auth/check",
    }),

    handleSignUp: builder.mutation<User, SignUp>({
      query: (authCredentials) => ({
        url: "/auth/signup",
        method: "POST",
        body: authCredentials,
      }),
      invalidatesTags: ["fetchUserProfileTag"],
    }),
    handleValidateEmail: builder.mutation<User, ValidateEmail>({
      query: (validateEmail) => ({
        url: "/auth/validate",
        method: "POST",
        body: validateEmail,
      }),
    }),
    handleValidateEmailResend: builder.mutation<User, ValidateEmailResend>({
      query: (validateEmailResend) => ({
        url: "/auth/validate/resend",
        method: "POST",
        body: validateEmailResend,
      }),
    }),
    handleForgot: builder.mutation<User, Forgot>({
      query: (forgotPassword) => ({
        url: "/auth/forgot",
        method: "POST",
        body: forgotPassword,
      }),
    }),
    handleForgotCheck: builder.mutation<User, ForgotPasswordCheck>({
      query: (forgotPasswordCheck) => ({
        url: "/auth/forgot/check",
        method: "POST",
        body: forgotPasswordCheck,
      }),
    }),
    handleForgotPassword: builder.mutation<User, ForgotPassword>({
      query: (forgotPassword) => ({
        url: "/auth/forgot/password",
        method: "POST",
        body: forgotPassword,
      }),
    }),
    handleSignIn: builder.mutation<User, SignIn>({
      query: (authCredentials) => ({
        url: "/auth/signin",
        method: "POST",
        body: authCredentials,
      }),
    }),
    handleSignInWithEmail: builder.mutation<User, SignInEmail>({
      query: (authCredentials) => ({
        url: "/auth/signin/email",
        method: "POST",
        body: authCredentials,
      }),
      invalidatesTags: ["fetchUserProfileTag"],
    }),
    handleLogout: builder.mutation<void, void>({
      query: () => ({
        url: "/auth/logout",
        method: "POST",
      }),
      invalidatesTags: ["fetchUserProfileTag"],
    }),
    handleChooseMethod: builder.mutation<void, ChooseMethod>({
      query: (authCredentials) => ({
        url: "/auth/otp/post",
        method: "POST",
        body: authCredentials,
      }),
    }),
    handleOTPVerification: builder.mutation<void, OTPVerification>({
      query: (authCredentials) => ({
        url: "/auth/otp/verification",
        method: "POST",
        body: authCredentials,
      }),
    }),
    handleCreateNewPassword: builder.mutation<void, CreateNewPassword>({
      query: (authCredentials) => ({
        url: "/auth/updateOpenPasswordOTP",
        method: "POST",
        body: authCredentials,
      }),
    }),
    validatePhone: builder.mutation<void, string>({
      query: (phone) => ({
        url: "/auth/validate/phone",
        method: "POST",
        body: { phone },
      }),
      invalidatesTags: ["fetchUserProfileTag"],
    }),
    validatePhoneOTPAndHandleTwoFactorAuth: builder.mutation<void, string>({
      query: (otp) => ({
        url: "/auth/validate/phone/otp",
        method: "POST",
        body: { otp },
      }),
      invalidatesTags: ["fetchUserProfileTag"],
    }),
  }),
});

export const {
  useHandleCheckQuery,
  useHandleSignUpMutation,
  useHandleValidateEmailMutation,
  useHandleValidateEmailResendMutation,
  useHandleForgotMutation,
  useHandleForgotCheckMutation,
  useHandleForgotPasswordMutation,
  useHandleSignInMutation,
  useHandleSignInWithEmailMutation,
  useHandleLogoutMutation,
  useHandleChooseMethodMutation,
  useHandleOTPVerificationMutation,
  useHandleCreateNewPasswordMutation,
  useValidatePhoneMutation,
  useValidatePhoneOTPAndHandleTwoFactorAuthMutation,
} = authApi;
