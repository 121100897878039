import React from "react";
import Form from "react-bootstrap/Form";
import Typography from "../Typography";
import "./styles.scss";

interface OptionsSelectorProps {
  placeholderText?: string;
  options: {
    value?: string;
    label: string;
  }[];
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLSelectElement>) => void;
  className?: string;
  helperText?: string;
  state?: string;
}

const OptionsSelector: React.FC<OptionsSelectorProps> = ({
  placeholderText,
  options,
  name,
  value,
  onChange,
  onBlur,
  className,
  helperText,
  state,
}) => {
  const getClassName = () => {
    let baseClassName = `field-style`;

    if (state === "error") {
      baseClassName += " options-selector-error";
    } else if (state === "disabled") {
      baseClassName += " options-selector-disabled";
    }

    return baseClassName;
  };
  return (
    <div className="options-selector">
      <Form.Select
        aria-label="Default select example"
        value={value}
        onChange={onChange}
        className={`${getClassName()}
			${className ?? ""}`}
        name={name}
        onBlur={onBlur}
        // TODO: check if this is needed (not existing in the original code)
        // state={state}
      >
        <option
          disabled
          value=""
          className="placeholder-text"
        >
          {placeholderText}
        </option>
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </Form.Select>
      {helperText && (
        <Typography
          variant="small-text"
          className="helper-text"
        >
          {helperText}
        </Typography>
      )}
    </div>
  );
};

export default OptionsSelector;
