import { useState } from "react";
import { useLocation, Link, Navigate, useNavigate } from "react-router-dom";
import { Popover } from "antd";
import useDeviceStatus from "src/utils/responsive";
import "./style.scss";
import { SiteLogo, plusAddIcon, dorIcon, clientProfile, toggle } from "src/assets";
import { Button } from "src/components/common/Base";
import { ReactComponent as Cencalbtn } from "src/assets/icons/cencalbtn.svg";
import { useHandleLogoutMutation } from "src/api/auth/auth.service";

const SideMenubar: React.FC = () => {
  const { isDesktop } = useDeviceStatus();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const [handleLogout] = useHandleLogoutMutation();
  const navigate = useNavigate();
  const logoutButtonContent = <div className="popupLogout">Logout</div>;

  const sidebarOption = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Messenger", link: "/messenger" },
    { title: "Bookings", link: "/bookings" },
    { title: "Projects", link: "/projects" },
    { title: "Teams", link: "/teams" },
    { title: "Profile", link: "/profile" },
    { title: "Payments", link: "/payment" },
    { title: "Settings", link: "/settings" },
    { title: "Referral", link: "/referral" },
  ];

  return (
    <>
      <div className={!isSidebarOpen ? "responsiveMenu" : "sideMenubarRow"}>
        <div className="siteLogo">
          <a
            href="/siteLogo"
            className="logoImage"
          >
            <img
              src={SiteLogo}
              alt=""
            />
            <span className="siteName">forreels</span>
          </a>

          {!isDesktop && !isSidebarOpen && (
            <Button
              className="toggle"
              onClick={() => setSidebarOpen(!isSidebarOpen)}
            >
              <img
                src={toggle}
                alt=""
              />
            </Button>
          )}
          {!isDesktop && isSidebarOpen && (
            <Button
              className="closebtn"
              onClick={() => setSidebarOpen(!isSidebarOpen)}
            >
              <Cencalbtn />
            </Button>
          )}
        </div>

        {!isDesktop && isSidebarOpen && (
          <div className="navfunction">
            <div className="navlist">
              <ul className="navmenu">
                {sidebarOption.map((item, index) => {
                  const isActive = location.pathname === item.link;
                  return (
                    <Link
                      key={index}
                      to={item.link}
                      className={`navitem ${isActive ? "active" : ""}`}
                    >
                      {item.title}
                      {item.title === "Messenger" && <span className="notification">2</span>}
                    </Link>
                  );
                })}
              </ul>
            </div>
            <Button className="AddNewproject">
              <img
                src={plusAddIcon}
                alt=""
              />
              new project
            </Button>
            <div className="changeProfile">
              <h4 className="changeclient">Change to client profile</h4>
              <div className="clientProfile">
                <img
                  src={clientProfile}
                  alt=""
                  className="clientimage"
                />
                <div className="clientname">
                  <h2>Allendra Freeman</h2>
                  <p>Creator</p>
                </div>
                <Button className="mobileLogout">Log out</Button>
              </div>
            </div>
          </div>
        )}

        {isDesktop && (
          <div className="navfunction">
            <div className="navlist">
              <ul className="navmenu">
                {sidebarOption.map((item, index) => {
                  const isActive = location.pathname === item.link;
                  return (
                    <Link
                      key={index}
                      to={item.link}
                      className={`navitem ${isActive ? "active" : ""}`}
                    >
                      {item.title}
                      {item.title === "Messenger" && <span className="notification">2</span>}
                    </Link>
                  );
                })}
              </ul>
            </div>
            <Button className="AddNewproject">
              <img
                src={plusAddIcon}
                alt=""
              />
              new project
            </Button>

            <div className="changeProfile">
              <h4 className="changeclient">Change to client profile</h4>

              <Popover
                content={logoutButtonContent}
                trigger={"click"}
                placement="top"
              >
                <div className="clientProfile">
                  <img
                    src={clientProfile}
                    alt=""
                    className="clientimage"
                  />
                  <div className="clientname">
                    <h2>Allendra Freeman</h2>
                    <p>Creator</p>
                  </div>
                  <Button
                    className="logoutbtn"
                    onClick={() => {
                      handleLogout();
                      navigate("/auth/sign-in");
                    }}
                  >
                    <img
                      src={dorIcon}
                      alt=""
                    />
                  </Button>
                </div>
              </Popover>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SideMenubar;
