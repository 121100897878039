import React from "react";
import { Modal } from "react-bootstrap";
import { GenericComponentProps } from "../../../../interfaces/component";
import "./style.scss";
import { Button } from "../../Base";
import { ReactComponent as Cencalbtn } from "src/assets/icons/cencalbtn.svg";

interface CustomModalProps extends GenericComponentProps {
  show: boolean;
  handleClose: () => void;
  className?: string;
  size?: "sm" | "lg" | "xl";
  ref?: React.RefObject<HTMLVideoElement>;
  fullscreen?: true | string | "sm-down" | "md-down" | "lg-down" | "xl-down" | "xxl-down";
  scrollable?: boolean;
  hideCloseBtn?: boolean;
}

const CustomModal: React.FC<CustomModalProps> = ({
  show,
  children,
  handleClose,
  className,
  size,
  fullscreen,
  scrollable,
  hideCloseBtn,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className={`${className ? className : ""} maincustomModal`}
      size={size}
      fullscreen={fullscreen}
      scrollable={scrollable}
    >
      <Modal.Body className="customModal">
        <div className="customeItem">
          {children}
          {!hideCloseBtn && (
            <Button
              className="closeBtn"
              onClick={handleClose}
            >
              <Cencalbtn className="closeimage" />
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CustomModal;
