import React from "react";
import { Switch } from "antd";
import "./style.scss";

interface ToggleSwitchProps {
  onChange: () => void;
  checked?: boolean;
  checkedChildren?: string;
  unCheckedChildren?: string;
  defaultChecked?: string;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  onChange,
  checked,
  checkedChildren,
  unCheckedChildren,
  defaultChecked,
}) => (
  <Switch
    checked={checked}
    onChange={onChange}
    checkedChildren={checkedChildren}
    unCheckedChildren={unCheckedChildren}
    defaultChecked
  />
);

export default ToggleSwitch;
