import React from "react";
import { Spinner } from "react-bootstrap";

const SpinnerLoading = () => {
  return (
    <Spinner
      animation="border"
      variant="light"
    />
  );
};

export default SpinnerLoading;
