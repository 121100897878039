import { useState } from "react";
import { X } from "react-feather";
import { CustomModal } from "../../Layout";
import Button from "../Button";
import Input from "../Input";
import Typography from "../Typography";
import "./styles.scss";

interface SelectProps {
  text?: string;
  options: string[];
  topTextOptions?: string[];
  onChange?: (item: string) => void;
}

const Select: React.FC<SelectProps> = ({ text, options, topTextOptions, onChange }) => {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);

  const handleItemClick = (item: string) => {
    if (item === "Custom") {
      setShowModal(true);
    } else {
      setSelectedItem(item);
      if (onChange) {
        onChange(item);
      }
    }
  };

  const closeModal = () => setShowModal(false);

  return (
    <>
      <div className="full-container">
        <div className={`${text ? "divider" : "divider-without-text"}`}></div>
        <div className={`${text ? "select-container " : "select-container-without-text"}`}>
          {text && (
            <div className="text-area">
              <Typography
                variant="subtitle"
                className="select-text"
              >
                {text}
              </Typography>
            </div>
          )}
          <div className="options">
            <ul
              className={`${text ? "list" : "list-without-text"}`}
              style={{ flexWrap: "wrap" }}
            >
              {options.map((item, index) => (
                <Button
                  key={index}
                  className={`${topTextOptions ? "listItem-with-top-text" : "listItem"} ${selectedItem === item ? "selected" : ""}`}
                  // TODO: handleItemKeyPress function is not defined
                  // onKeyDown={(e) => handleItemKeyPress(e, item)}
                  onClick={() => handleItemClick(item)}
                >
                  {topTextOptions && (
                    <Typography
                      variant="body-1"
                      className="button-text-style"
                    >
                      {topTextOptions[index]}
                    </Typography>
                  )}
                  <Typography
                    variant={`${topTextOptions ? "subtitle" : "small-text"}`}
                    className="button-text-style"
                  >
                    {item}
                  </Typography>
                </Button>
              ))}
            </ul>
          </div>
        </div>

        <div className="small-screen-select-container">
          <div className="text-area-container">
            <Typography
              variant="subtitle"
              className="select-text"
            >
              {text}
            </Typography>
          </div>
          <div className="all-options">
            <ul className="list-style">
              {options.map((item, index) => (
                <Button
                  key={index}
                  className={`list-Item ${selectedItem === item ? "selected" : ""}`}
                  // TODO: handleItemKeyPress function is not defined
                  // onKeyPress={(e) => handleItemKeyPress(e, item)}
                  onClick={() => handleItemClick(item)}
                >
                  <Typography variant="body-2">{item}</Typography>
                </Button>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {showModal && (
        <CustomModal
          className="modal-stylingg"
          // TODO: size 'md' is not a valid prop
          // size={'md'}
          show={showModal}
          handleClose={closeModal}
        >
          <div className="modal-form-container">
            <Button
              className="button-styless"
              onClick={closeModal}
            >
              <X className="icon-stylee" />
            </Button>
            <Typography
              variant="subtitle"
              className="heading-Style"
            >
              Add Custom Tip
            </Typography>
            <div className="input-button-container">
              <div className="amount-input-container">
                <Typography variant="small-text">Enter Amount</Typography>

                <Input
                  type="text"
                  className="input-style"
                  placeholder="$"
                />
              </div>
              <Button color="primary">Done</Button>
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default Select;
