import AppRouter from "./AppRouter";
import PrivateContainer from "./PrivateContainer";
import PublicContainer from "./PublicContainer";
import AactivateAccountModel from "src/components/models/ActivateAccountModel";
import { useHandleCheckQuery } from "src/api/auth/auth.service";
import { useFetchUserProfileQuery } from "src/api/user/user.service";

const DefaultLayout = () => {
  const { data: userProfile } = useFetchUserProfileQuery();
  const { data: handleCheckData } = useHandleCheckQuery();
  const isAuth = handleCheckData?.authenticated ?? false;
  const accountStatusType = { active: "active", inActive: "in-active" };
  const currentUserStatus = userProfile?.accountStatus;

  return (
    <>
      {isAuth == true ? (
        <PrivateContainer>
          <AppRouter isAuth={true} />
          {currentUserStatus && currentUserStatus == accountStatusType.inActive && <AactivateAccountModel />}
        </PrivateContainer>
      ) : null}
      {isAuth == false ? (
        <PublicContainer>
          <AppRouter isAuth={false} />
        </PublicContainer>
      ) : null}
    </>
  );
};

export default DefaultLayout;
