import React from "react";
import { GenericComponentProps } from "src/interfaces/component";
import "./style.scss";
import { SideMenubar } from "../../Layout";

const Wrapper: React.FC<GenericComponentProps> = ({ children }) => {
  return (
    <div className="main-container">
      <aside className="sidebar">
        <SideMenubar />
      </aside>

      <main className="main">{children}</main>
    </div>
  );
};

export default Wrapper;
