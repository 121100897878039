import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, InpuWithTitle } from "src/components/common/Base";
import { CustomModal } from "src/components/common/Layout";
import { useActivateClosedAccountMutation, useFetchUserProfileQuery } from "src/api/user/user.service";
import { showMessage } from "src/utils/showMessage/showMessage";
import "./style.scss";

const AactivateAccountModel = () => {
  const [activateClosedAccount] = useActivateClosedAccountMutation();
  const { data: userProfile } = useFetchUserProfileQuery();

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().min(8, "password must be at least 8 characters").required("password is required"),
    }),
    onSubmit: (values) => {
      activateClosedAccount(values.password)
        .then(() => {
          showMessage({ type: "success", text: "Your account is now activated." });
        })
        .catch(() => {
          formik.setErrors({ password: "Invalid password" });
        });
    },
  });

  return (
    <>
      {userProfile?.accountStatus != "active" && (
        <div className="ActivateAccountWrapper">
          <CustomModal
            show={true}
            size="lg"
            handleClose={() => {}}
            className="activeAccountCard"
            hideCloseBtn={true}
          >
            <form
              onSubmit={formik.handleSubmit}
              className="relogin-form"
            >
              <h4 className="relogin-heading">Welcome Back, {`${userProfile?.firstName} ${userProfile?.lastName}`}</h4>

              <div className="input-relogin">
                <InpuWithTitle
                  type="password"
                  title="Your password"
                  name="password"
                  placeholder="Enter your password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.password && formik.errors.password ? formik.errors.password : undefined}
                />
              </div>

              <Button
                className="activateBtn"
                type="submit"
              >
                Activate Account
              </Button>
            </form>
          </CustomModal>
        </div>
      )}
    </>
  );
};

export default AactivateAccountModel;
