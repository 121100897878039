import React from "react";
import "./styles.scss";

interface RadioButtonProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  id?: string;
  checked?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const RadioButton: React.FC<RadioButtonProps> = ({ value, onChange, name, id, checked, onBlur }) => {
  return (
    <>
      <input
        type="radio"
        value={value}
        onChange={onChange}
        name={name}
        id={id}
        className="custom-radio-input"
        checked={checked}
        onBlur={onBlur}
      />
      <span className="custom-radio-checkmark"></span>
    </>
  );
};

export default RadioButton;
