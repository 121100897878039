import React from "react";
import { Form } from "react-bootstrap";
import "./styles.scss";

interface CheckBoxProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  value: string;
  name: string;
}

const CheckBox: React.FC<CheckBoxProps> = ({ onChange, onBlur, value, name }) => {
  return (
    <div className="check-box-container">
      <Form.Check
        type="checkbox"
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};

export default CheckBox;
