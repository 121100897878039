import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Plus } from "react-feather";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { ImageProps } from "../../../../interfaces/component";
import Typography from "../Typography";
import "./style.scss";

interface CarouselSliderProps {
  images: ImageProps[];
}

interface SliderSettings {
  centerPadding: string;
  dots: boolean;
  customPaging: (i: number) => JSX.Element;
  infinite: boolean;
  slidesToShow: number;
  slidesToScroll: number;
  autoplay: boolean;
  speed: number;
  autoplaySpeed: number;
  cssEase: string;
  arrows: boolean;
  focusOnSelect: boolean;
}

const CarouselSlider: React.FC<CarouselSliderProps> = ({ images }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const [showDescription, setShowDescription] = useState(false);
  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  const handleDotClick = (index: number) => {
    setCurrentSlide(index);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, index: number) => {
    if (event.key === "Enter" || event.key === " ") {
      setCurrentSlide(index);
    }
  };

  const settings: SliderSettings = {
    centerPadding: "0px",
    dots: true,
    customPaging: function (i: number) {
      return (
        <div
          className={`custom-dot ${i === currentSlide ? "active" : ""}`}
          onClick={() => handleDotClick(i)}
          onKeyDown={(event) => handleKeyDown(event, i)}
          role="button"
          tabIndex={0}
        ></div>
      );
    },
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    arrows: false,
    focusOnSelect: false,
  };

  return (
    <Slider
      {...settings}
      beforeChange={(newIndex) => setCurrentSlide(newIndex)}
    >
      {images.map((item, index) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
          key={index}
          className={`bg-image-text-container`}
          onClick={item.description ? toggleDescription : undefined}
          // onKeyDown={handleKeyDown}
          role="button"
          tabIndex={0}
        >
          {item.src && (
            <img
              src={item.src}
              alt=""
              className="image-style"
            />
          )}
          <div className={`image-heading ${showDescription ? "show" : ""}`}>
            <div className={`heading-icon ${item.textAlignment}`}>
              <Typography
                variant="h3"
                className={`stc-heading`}
              >
                {item.text}
              </Typography>
            </div>
            {showDescription && (
              <div className={`stc-description `}>
                <Typography variant="small-text">{item.description}</Typography>
              </div>
            )}
          </div>
          <div className="icon-styling">
            {item.showIcon && (
              <Button
                className={`stc-button`}
                onClick={toggleDescription}
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              >
                <Plus className={`stc-icon ${showDescription ? "cross" : ""}`} />
              </Button>
            )}
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default CarouselSlider;
