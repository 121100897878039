import { createElement, lazy, Suspense } from "react";
import Wrapper from "src/components/common/components/Wrapper";

const routes = {
  landingPage: {
    path: "/",
    label: "Landing Page",
    component: <Suspense>{createElement(lazy(() => import("../pages/landing-page")))}</Suspense>,
  },
  welcomeSignUp: {
    path: "/auth/sign-up",
    label: "Welcome SignUp",
    component: <Suspense>{createElement(lazy(() => import("../pages/welcomeSignUp")))}</Suspense>,
  },
  verificationCode: {
    path: "/auth/validation",
    label: "Verification Code",
    component: <Suspense>{createElement(lazy(() => import("../pages/verificationCode")))}</Suspense>,
  },
  signInEmail: {
    path: "/auth/sign-in",
    label: "Sign In with Email",
    component: <Suspense>{createElement(lazy(() => import("../pages/signInEmail")))}</Suspense>,
  },
  signInEmailPassword: {
    path: "/auth/sign-in/email",
    label: "Sign In with Email and Password",
    component: <Suspense>{createElement(lazy(() => import("../pages/signInEmailPassword")))}</Suspense>,
  },
  createAccount: {
    path: "/auth/sing-up/account",
    label: "Create Account",
    component: <Suspense>{createElement(lazy(() => import("../pages/createAccount")))}</Suspense>,
  },
  createPassword: {
    path: "/auth/sign-up/password",
    label: "Create Password",
    component: <Suspense>{createElement(lazy(() => import("../pages/createPassword")))}</Suspense>,
  },
  findAccount: {
    path: "/auth/forgot",
    label: "Forgot Account",
    component: <Suspense>{createElement(lazy(() => import("../pages/findAccount")))}</Suspense>,
  },
  chooseMethod: {
    path: "/auth/forgot/method",
    label: "Choose Method",
    component: <Suspense>{createElement(lazy(() => import("../pages/chooseMethod")))}</Suspense>,
  },
  createNewPassword: {
    path: "/auth/forgot/password",
    label: "Create New Password",
    component: <Suspense>{createElement(lazy(() => import("../pages/createNewPassword")))}</Suspense>,
  },
  passwordChangeSuccess: {
    path: "/auth/forgot/success",
    label: "Password Change Success",
    component: <Suspense>{createElement(lazy(() => import("../pages/passwordChangeSuccess")))}</Suspense>,
  },
  subscriptionConfirmation: {
    path: "/subscription-confirmation",
    label: "Subscription Confirmation",
    component: <Suspense>{createElement(lazy(() => import("../pages/subscription-confirmation")))}</Suspense>,
  },
  careers: {
    path: "/careers",
    label: "Careers",
    component: <Suspense>{createElement(lazy(() => import("../pages/careers")))}</Suspense>,
  },
  commonQuestions: {
    path: "/questions/common",
    label: "Common Questions",
    component: <Suspense>{createElement(lazy(() => import("../pages/common-questions")))}</Suspense>,
  },
  customers: {
    path: "/customers",
    label: "Customers",
    component: <Suspense>{createElement(lazy(() => import("../pages/customers")))}</Suspense>,
  },
  superProducers: {
    path: "/super-producers",
    label: "Super Producers",
    component: <Suspense>{createElement(lazy(() => import("../pages/super-producers")))}</Suspense>,
  },
  seeStories: {
    path: "/stories",
    label: "See Stories",
    component: <Suspense>{createElement(lazy(() => import("../pages/see-stories")))}</Suspense>,
  },
  whatsNew: {
    path: "/whats-new",
    label: "Whats New",
    component: <Suspense>{createElement(lazy(() => import("../pages/whats-new")))}</Suspense>,
  },
  clientReview: {
    path: "/reviews",
    label: "Client Review",
    component: <Suspense>{createElement(lazy(() => import("../pages/clientReview")))}</Suspense>,
  },
  accounts: {
    path: "/accounts",
    label: "Accounts",
    component: <Suspense>{createElement(lazy(() => import("../pages/accounts")))}</Suspense>,
  },
  accountDetails_SuperProducer: {
    path: "/account-details-superproducer",
    label: "Account Details - SuperProducer",
    component: <Suspense>{createElement(lazy(() => import("../pages/accountDetails_SuperProducer")))}</Suspense>,
  },
  accountDetails_Creator: {
    path: "/account-details-creator",
    label: "Account Details - Creator",
    component: <Suspense>{createElement(lazy(() => import("../pages/accountDetails_Creator")))}</Suspense>,
  },
  accountDetails_Clients: {
    path: "/account-details-clients",
    label: "Account Details - Clients",
    component: <Suspense>{createElement(lazy(() => import("../pages/accountDetails_Clients")))}</Suspense>,
  },
  accountDetails_Manager: {
    path: "/account-details-manager",
    label: "Account Details - Manager",
    component: <Suspense>{createElement(lazy(() => import("../pages/accountDetails_Manager")))}</Suspense>,
  },
  creatorOnBoarding_AfterSignUp: {
    path: "/creator-onboarding-after-signup",
    label: "Creator OnBoarding After SignUp",
    component: <Suspense>{createElement(lazy(() => import("../pages/createrOnBoarding_AfterSignUp")))}</Suspense>,
  },
  contactForm: {
    path: "/contact-form",
    label: "Contact Form",
    component: <Suspense>{createElement(lazy(() => import("../pages/contactForm")))}</Suspense>,
  },
  contactFormConfirmation: {
    path: "/contact-form-confirmation",
    label: "Contact Form Confirmation",
    component: <Suspense>{createElement(lazy(() => import("../pages/contactFormConfirmation")))}</Suspense>,
  },
  dashboard: {
    path: "/dashboard",
    label: "Clients Dashboard",
    component: (
      <Suspense>
        <Wrapper>{createElement(lazy(() => import("../pages/dashboard")))}</Wrapper>
      </Suspense>
    ),
  },
  payment: {
    path: "/payment",
    label: "Clients Payment Dashboard",
    component: (
      <Suspense>
        <Wrapper>{createElement(lazy(() => import("../pages/payment")))}</Wrapper>
      </Suspense>
    ),
  },
  projects: {
    path: "/projects",
    label: "Clients Projects Dashboard",
    component: (
      <Suspense>
        <Wrapper>{createElement(lazy(() => import("../pages/projects")))}</Wrapper>
      </Suspense>
    ),
  },
  profile: {
    path: "/profile",
    label: "Profile page",
    component: (
      <Suspense>
        <Wrapper>{createElement(lazy(() => import("../pages/profilePage")))}</Wrapper>
      </Suspense>
    ),
  },
  setting: {
    path: "/settings",
    label: "setting page",
    component: (
      <Suspense>
        <Wrapper>{createElement(lazy(() => import("../pages/settingPage")))}</Wrapper>
      </Suspense>
    ),
  },
  //   creators: {
  //     path: "/creators",
  //     label: "creators",
  //     component: <Suspense>{createElement(lazy(() => import("../pages/creators")))}</Suspense>,
  //   },
  //   creativesprofile: {
  //     path: "/creatives-profile",
  //     label: "Creatives Profile",
  //     component: <Suspense>{createElement(lazy(() => import("../pages/creativesProfile")))}</Suspense>,
  //   },
  favorite: {
    path: "/favorite",
    label: "favorite page",
    component: (
      <Suspense>
        <Wrapper>{createElement(lazy(() => import("./../pages/favoritePage")))}</Wrapper>
      </Suspense>
    ),
  },
  authByEmailLink: {
    path: "/auth/email",
    label: "Auth by Email Link",
    component: (
      <Suspense>
        <>{createElement(lazy(() => import("../pages/emailAuthentication")))}</>
      </Suspense>
    ),
  },
  creators: {
    path: "/creators",
    label: "creators",
    component: <Suspense>{createElement(lazy(() => import("../pages/creators")))}</Suspense>,
  },
  creativesprofile: {
    path: "/creatives-profile/:uuid",
    label: "Creatives Profile",
    component: <Suspense>{createElement(lazy(() => import("../pages/creativesProfile")))}</Suspense>,
  },
};

export const publicRoutes = [
  routes.accounts,
  // routes.comingSoon,
  routes.landingPage,
  routes.careers,
  routes.subscriptionConfirmation,
  routes.commonQuestions,
  routes.customers,
  routes.superProducers,
  routes.seeStories,
  routes.whatsNew,
  routes.clientReview,
  routes.contactForm,
  routes.contactFormConfirmation,
  routes.creators,
  routes.creativesprofile,
  routes.favorite,
];
export const authRoutes = [
  routes.welcomeSignUp,
  routes.createAccount,
  routes.verificationCode,
  routes.createPassword,
  routes.findAccount,
  routes.createNewPassword,
  routes.passwordChangeSuccess,
  routes.chooseMethod,
  routes.signInEmail,
  routes.signInEmailPassword,
  routes.authByEmailLink,
];
export const privateRoutes = [
  routes.accountDetails_SuperProducer,
  routes.accountDetails_Creator,
  routes.accountDetails_Clients,
  routes.creatorOnBoarding_AfterSignUp,
  routes.accountDetails_Manager,
  routes.dashboard,
  routes.payment,
  routes.profile,
  routes.setting,
  routes.projects,
];
