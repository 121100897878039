import React from "react";
import "./style.scss";

interface AccordionCardProps {
  title: string;
  icon?: React.ReactNode;
  text?: string;
  children: React.ReactNode;
  className?: string;
  accordionId: string;
  isOpen?: boolean;
}

const AccordionCard: React.FC<AccordionCardProps> = ({
  title,
  icon,
  text,
  children,
  className,
  isOpen,
  accordionId,
}) => {
  return (
    <div className={`${className} settingAccordion`}>
      <div
        className="accordion accordion-flush"
        id="accordionFlushExample"
      >
        <div className={`accordion-item ${isOpen ? "open" : ""}`}>
          <h2
            className="accordion-header"
            id={`flush-heading${accordionId}`}
          >
            <button
              className={`accordion-button ${isOpen ? "open" : "collapsed"}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#flush-collapse${accordionId}`}
              aria-expanded={isOpen ? "true" : "false"}
              aria-controls={`flush-collapse${accordionId}`}
            >
              <span className="imageIcon">
                {typeof icon === "string" ? (
                  <img
                    src={icon}
                    alt=""
                  />
                ) : (
                  icon
                )}
              </span>
              <span className="accordionTitle">
                <span className="accordion-Tab-heading">{title}</span>
                <span className="accordion-Tab-info">{text}</span>
              </span>
            </button>
          </h2>

          {children}
        </div>
      </div>
    </div>
  );
};

export default AccordionCard;
