import React, { ChangeEventHandler, FC, FocusEventHandler, useState } from "react";
import "./style.scss";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import Button from "../Button";

type InputType = "text" | "email" | "password" | "tel" | "search" | "textarea" | "number";

interface InputProps {
  title?: string;
  type: InputType;
  value?: string | number;
  name: string;
  onChange?: ChangeEventHandler;
  onBlur?: FocusEventHandler;
  placeholder?: string;
  error?: string;
  min?: number;
  max?: number;
  maxLength?: number;
  minLength?: number;
}

const InputWithTitle: FC<InputProps> = ({
  title,
  type,
  value,
  name,
  onBlur,
  onChange,
  error,
  placeholder,
  min,
  max,
  maxLength,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {title && <h1 className="title">{title}</h1>}
      {type === "textarea" ? (
        <div className="mainInput">
          <textarea
            className="textarea"
            name={name}
            value={value as string}
            onChange={onChange as ChangeEventHandler<HTMLTextAreaElement>}
            onBlur={onBlur as FocusEventHandler<HTMLTextAreaElement>}
            placeholder={placeholder}
            maxLength={maxLength}
          />
          {error && <span className="errorInput">{error}</span>}
        </div>
      ) : (
        <div className="mainInput">
          <div className="input-container">
            <input
              className="input"
              type={type === "password" ? (showPassword ? "text" : "password") : type}
              placeholder={placeholder}
              value={value}
              name={name}
              min={min}
              max={max}
              onChange={onChange}
              onBlur={onBlur}
            />
            {type === "password" && (
              <Button
                type="button"
                className="toggle-password"
                onClick={handleTogglePassword}
              >
                {showPassword ? (
                  <FaEyeSlash
                    size={24}
                    color="white"
                    style={{ backgroundColor: "transparent" }}
                  />
                ) : (
                  <FaEye
                    size={24}
                    color="white"
                    style={{ backgroundColor: "transparent" }}
                  />
                )}
              </Button>
            )}
          </div>
          {error && <span className="errorInput">{error}</span>}
        </div>
      )}
    </>
  );
};

export default InputWithTitle;
