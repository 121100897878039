import { Navigate, Route, Routes } from "react-router-dom";
import Default from "./Default";
import { authRoutes, privateRoutes, publicRoutes } from "./routes";
import { useEffect, useState } from "react";

type RouteType = {
  isAuth: boolean;
};

const AppRouter: React.FC<RouteType> = ({ isAuth }) => {
  const [isAuthentication, setIsAuthentication] = useState(isAuth);

  useEffect(() => {
    if (isAuth) {
      setIsAuthentication(isAuth);
    }
  }, [isAuth]);

  const publicPageRoutes = publicRoutes.map(({ label, path, component }) => {
    return (
      <Route
        key={label}
        path={`${path}`}
        element={component}
      />
    );
  });

  const authPageRoutes = authRoutes.map(({ label, path, component }) => {
    return (
      <Route
        key={label}
        path={`${path}`}
        element={component}
      />
    );
  });

  const privatePageRoutes = privateRoutes.map(({ label, path, component }) => {
    return (
      <Route
        key={label}
        path={`${path}`}
        element={isAuthentication ? component : <Navigate to="/auth/sign-in" />}
      />
    );
  });

  return (
    <Routes>
      <Route
        path="/"
        element={<Default />}
      >
        {/* public routes */}
        <>{publicPageRoutes}</>

        {/* auth routes */}
        <>{authPageRoutes}</>

        {/* private routes */}
        <>{privatePageRoutes}</>

        {/* catch all */}
        <Route
          path="*"
          element={<Navigate to={isAuthentication ? "/" : "/auth/sign-in"} />}
        />
      </Route>
    </Routes>
  );
};

export default AppRouter;
