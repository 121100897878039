import React from "react";
import { useDropzone, DropzoneState } from "react-dropzone";

interface ReactDropzoneProps {
  onDrop: (acceptedFiles: File[]) => void;
  maxFiles?: number;
  multiple?: boolean;
  children: React.ReactNode;
}

const ReactDropzone: React.FC<ReactDropzoneProps> = ({ onDrop, maxFiles = 1, multiple = false, children }) => {
  const { getRootProps, getInputProps }: DropzoneState = useDropzone({
    maxFiles,
    multiple,
    onDrop,
  });

  return (
    <div {...getRootProps({ className: "dropzone-container" })}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

export default ReactDropzone;
