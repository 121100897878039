import React from "react";
import { fileUploadIcon } from "src/assets";
import ReactDropzone from "../ReactDropzone";
import "./style.scss"; // Import the SCSS styles for DropBox design

interface DropBoxProps {
  title: string;
  maxSize: number;
  sizeType: "MB" | "KB" | "GB";
  onDrop: (file: File[]) => void;
}

const DropBox: React.FC<DropBoxProps> = ({ title, maxSize, sizeType, onDrop }) => {
  return (
    <ReactDropzone onDrop={onDrop}>
      <div className="dragFile">
        <div className="dragIcon">
          <img
            src={fileUploadIcon}
            alt="upload"
          />
        </div>
        <h4 className="title">{title}</h4>
        <p className="text">{`(Max file size: ${maxSize} ${sizeType})`}</p>
      </div>
    </ReactDropzone>
  );
};

export default DropBox;
