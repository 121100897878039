import React from "react";
import { GenericComponentProps } from "../../../../interfaces/component";
import "./styles.scss";

interface BlueSectionProps extends GenericComponentProps {
  className?: string;
  style?: object;
}

const BlueSection: React.FC<BlueSectionProps> = ({ className, children }) => {
  const combinedClassName = className ? `blue-section ${className}` : "blue-section";

  return <div className={combinedClassName}>{children}</div>;
};

export default BlueSection;
