import applyIcon from "./icons/applyIcon.svg";
import arrowIcon from "./icons/arrow-icon.svg";
import BankhouseIcon from "./icons/BankhouseIcon.svg";
import cameraSuitcaseIcon from "./icons/cameraSuitcaseIcon.svg";
import Cencalbtn from "./icons/cencalbtn.svg";
import closeAccountIcon from "./icons/closeAccountIcon.svg";
import CloseBtn from "./icons/closeBtnIcon.svg";
import connectedLinkIcon from "./icons/connectedLink.svg";
import dollarIcon from "./icons/dollericon.svg";
import dorIcon from "./icons/dotIcon.svg";
import editIcon from "./icons/editeIcon.svg";
import emailBoxIcon from "./icons/emailBox.svg";
import emailIcon from "./icons/emailIcon.svg";
import eyesIcon from "./icons/eyesIcon.svg";
import facebook from "./icons/facebook.svg";
import facebookIcon from "./icons/facebookIcon.svg";
import FileshareIcon from "./icons/fileshareIcon.svg";
import fileUploadIcon from "./icons/fileUploadIcon.svg";
import logo from "./icons/forreels-logo.svg";
import instagram from "./icons/instagram.svg";
import instagramIcon from "./icons/instagramIcon.svg";
import languageIcon from "./icons/languageIcon.svg";
import linkedinIcon from "./icons/linkedinIcon.svg";
import loactionIcon from "./icons/loactionIcon.svg";
import lockIcon from "./icons/lockIcon.svg";
import logOutIcon from "./icons/logOutIcon.svg";
import vector1 from "./icons/lp1.svg";
import vector2 from "./icons/lp2.svg";
import vector3 from "./icons/lp3.svg";
import vector4 from "./icons/lp4.svg";
import memberVideoIcon from "./icons/memberVideoIcon.svg";
import messengerIcon from "./icons/messengerIcon.svg";
import mobileIcon from "./icons/mobileIcon.svg";
import plusIcon from "./icons/PlusIcon.svg";
import sendMsgIcon from "./icons/sendMsg.svg";
import shareIcon from "./icons/shareIcon.svg";
import suitcaseIcon from "./icons/suitcaseIcon.svg";
import tiktokIcon from "./icons/tiktokIcon.svg";
import toggle from "./icons/toggle.svg";
import twitter from "./icons/twitter.svg";
import vector5 from "./icons/Vector5.svg";
import vector6 from "./icons/Vector6.svg";
import vector7 from "./icons/Vector7.svg";
import videoIcon from "./icons/videoIcon.svg";
import vimeoIcon from "./icons/vimeoIcon.svg";
import youtube from "./icons/youtube.svg";
import notificationIcon from "./images/notificationIconW.svg";
import plusAddIcon from "./images/plusadd.svg";

function parseUrl(url: string) {
  return process.env.REACT_APP_ASSETS_BASE_URL + url;
}
const profileIcon = parseUrl("/images/profileIcon.png");
const benderless = parseUrl("/images/benderless.png");
const careersImage1 = parseUrl("/images/careers-image1.png");
const careersImage2 = parseUrl("/images/careers-image2.png");
const ciara = parseUrl("/images/ciara.png");
const cover1 = parseUrl("/images/cover1.png");
const crush = parseUrl("/images/crush.png");
const customerSlider1 = parseUrl("/images/customer-slider-1.png");
const customerSlider2 = parseUrl("/images/customer-slider-2.png");
const customerSlider3 = parseUrl("/images/customer-slider-3.png");
const footerLogo = parseUrl("/images/footer-logo.png");
const hope = parseUrl("/images/hope.png");
const hypergong = parseUrl("/images/hypergong.png");
const interstellar = parseUrl("/images/interstellar.png");
const jumalio = parseUrl("/images/jumalio.png");
const landingBottom1 = parseUrl("/images/landing-bottom-1.png");
const landingBottom2 = parseUrl("/images/landing-bottom-2.png");
const landingBottom3 = parseUrl("/images/landing-bottom-3.png");
const landingBottom4 = parseUrl("/images/landing-bottom-4.png");
const landingBottom5 = parseUrl("/images/landing-bottom-5.png");
const landingBottom6 = parseUrl("/images/landing-bottom-6.png");
const landingLeft1 = parseUrl("/images/landing-left-1.png");
const landingLeft2 = parseUrl("/images/landing-left-2.png");
const landingLeft3 = parseUrl("/images/landing-left-3.png");
const landingLeft4 = parseUrl("/images/landing-left-4.png");
const landingRight1 = parseUrl("/images/landing-right-1.png");
const landingRight2 = parseUrl("/images/landing-right-2.png");
const landingRight3 = parseUrl("/images/landing-right-3.png");
const landingRight4 = parseUrl("/images/landing-right-4.png");
const landingTop1 = parseUrl("/images/landing-top-1.png");
const landingTop2 = parseUrl("/images/landing-top-2.png");
const landingTop3 = parseUrl("/images/landing-top-3.png");
const spaceX = parseUrl("/images/spaceX.png");
const uber = parseUrl("/images/uber.png");
const wildDreams = parseUrl("/images/wild-dreams.png");
const wolfboy = parseUrl("/images/wolfboy.png");
const logoBlack = parseUrl("/logos/logo-black.png");
const logoBlue = parseUrl("/logos/logo-blue.png");
const creativeCollaborationImg1 = parseUrl("/images/creative-collaboration/creative-collaboration-img-1.png");
const creativeCollaborationImg2 = parseUrl("/images/creative-collaboration/creative-collaboration-img-2.png");
const creativeCollaborationImg3 = parseUrl("/images/creative-collaboration/creative-collaboration-img-3.png");
const creativeCollaborationImg4 = parseUrl("/images/creative-collaboration/creative-collaboration-img-4.png");
const creativeCollaborationImg5 = parseUrl("/images/creative-collaboration/creative-collaboration-img-5.png");
const creativeCollaborationImg6 = parseUrl("/images/creative-collaboration/creative-collaboration-img-6.png");
const creativeCollaborationImg7 = parseUrl("/images/creative-collaboration/creative-collaboration-img-7.png");
const cameraImg = parseUrl("/images/camera-img.png");
const haderbachLogo = parseUrl("/images/haderbach-logo.png");
const videoBg = parseUrl("/images/video-bg.png");
// See Stories Page
const ssAwards = parseUrl("/images/seeStories/awards-png-white.png");
const ssBanner = parseUrl("/images/seeStories/banner.png");
const sshaderbach = parseUrl("/images/seeStories/haderbach.png");
const ssSlide1 = parseUrl("/images/seeStories/slide1.png");
// Company logos
const blender = parseUrl("/company-logos/blender.png");
const ciaraLogo = parseUrl("/company-logos/ciara.png");
const crushLogo = parseUrl("/company-logos/crush.png");
const facebookLogo = parseUrl("/company-logos/facebook.png");
const gitlab = parseUrl("/company-logos/gitlab.png");
const google = parseUrl("/company-logos/google.png");
const iqvia = parseUrl("/company-logos/iqvia.png");
const jumalioLogo = parseUrl("/company-logos/jumalioLogo.png");
const loreal = parseUrl("/company-logos/loreal.png");
const rollins = parseUrl("/company-logos/rollins.png");
const spaceXLogo = parseUrl("/company-logos/spaceX.png");
const uberLogo = parseUrl("/company-logos/uber.png");
const wildDreamsLogo = parseUrl("/company-logos/wildDreamsLogo.png");
const accounts1 = parseUrl("/images/accounts1.png");
const accounts2 = parseUrl("/images/accounts2.png");
const accounts3 = parseUrl("/images/accounts3.png");
const spBodyImage = parseUrl("/images/sp-bodyimage.png");
const spCard1 = parseUrl("/images/sp-card-1.png");
const spCard2 = parseUrl("/images/sp-card-2.png");
const spCard3 = parseUrl("/images/sp-card-3.png");
const spCard4 = parseUrl("/images/sp-card-4.png");
const spCard5 = parseUrl("/images/sp-card-5.png");
const spHeader1 = parseUrl("/images/spHeader-01.png");
const spHeader2 = parseUrl("/images/spHeader-02.png");
const whatsNew1 = parseUrl("/images/whats-new-1.png");
const whatsNew2 = parseUrl("/images/whats-new-2.png");
const whatsNew3 = parseUrl("/images/whats-new-3.png");
const textLogoBlack = parseUrl("/logos/logoText-black.png");
const textLogoBlue = parseUrl("/logos/text-logo-blue.png");
const textLogo = parseUrl("/logos/text-logo.png");
const vision1 = parseUrl("/images/vision1.png");
const vision2 = parseUrl("/images/vision2.png");
const apple = parseUrl("/icons/apple.png");
const memberProfileIcon = parseUrl("/icons/memberProfileIcon.png");
const cameraImage = parseUrl("/icons/cameraImage.png");
const creativesProfile = parseUrl("/icons/creativesProfile.png");
const creativesProfileImg = parseUrl("/icons/creativesProfileImg.png");
const googleColored = parseUrl("/icons/google-colored.png");
const landingPageVideo = parseUrl("/videos/landingPageVideo.mp4");
const seeStoriesVideo = parseUrl("/videos/seeStoriesVideo.mp4");
const music1 = parseUrl("/images/music-1.jpg");
const music2 = parseUrl("/images/music-2.jpg");
const music3 = parseUrl("/images/music-3.jpg");
const music4 = parseUrl("/images/music-4.jpg");
const music5 = parseUrl("/images/music-5.jpg");
const music7 = parseUrl("/images/music-7.jpg");
const landingPage1 = parseUrl("/images/landing-page-1.png");
const landingPage2 = parseUrl("/images/landing-page-2.png");
const landingPage3 = parseUrl("/images/landing-page-3.png");
const landingPage4 = parseUrl("/images/landing-page-4.png");
const landingPage5 = parseUrl("/images/landing-page-5.png");
const landingPage6 = parseUrl("/images/landing-page-6.png");
const landingPage7 = parseUrl("/images/landing-page-7.png");
const landingPage8 = parseUrl("/images/landing-page-8.png");
const landingPage9 = parseUrl("/images/landing-page-9.png");
const adSuperProducer2 = parseUrl("/images/ad-superProducers2.png");
const adSuperProducer3 = parseUrl("/images/ad-superProducers3.png");
const adSuperProducer4 = parseUrl("/images/ad-superProducers4.png");
const adSuperProducer5 = parseUrl("/images/ad-superProducers5.png");
const adCreator1 = parseUrl("/images/ad-creator1.png");
const adCreator3 = parseUrl("/images/ad-creator3.png");
const adCreator4 = parseUrl("/images/ad-creator4.png");
const adClients1 = parseUrl("/images/ad-clients1.png");
const adClients2 = parseUrl("/images/ad-clients2.png");
const adClients3 = parseUrl("/images/ad-clients3.png");
const adClients4 = parseUrl("/images/ad-clients4.png");
const adManager1 = parseUrl("/images/ad-manager1.png");
const adManager2 = parseUrl("/images/ad-manager2.png");
const adManager3 = parseUrl("/images/ad-manager3.png");
const adManager4 = parseUrl("/images/ad-manager4.png");
const creatorOnBoardingAvatar = parseUrl("/images/createrOnBoarding_Avatar.png");
const ArriLogo = parseUrl("/images/arri-logo.png");
const MortonLogo = parseUrl("/images/morton-logo.png");
const RedLogo = parseUrl("/images/red-logo.png");
const ZeissLogo = parseUrl("/images/zeiss-logo.png");
const ssSliderMobile = parseUrl("/images/ss-slider-mobile.png");
const errorIcon = parseUrl("/images/errorIcon.png");
const locationImg = parseUrl("./icons/locationImg.png");
const projectcard = parseUrl("./icons/projectcard.png");
const clientProfile = parseUrl("/icons/clinetprofile.png");
const userIcon = parseUrl("/icons/ProfileImage.png");
const SiteLogo = parseUrl("/icons/siteLogo.png");
const modelImg = parseUrl("/images/creatives-Services/modelImg.png");
const services1 = parseUrl("/images/creatives-Services/services1.png");
const videoeditor = parseUrl("/images/creatives-Services/videoeditor.png");
const videoshoot = parseUrl("/images/creatives-Services/videoshoot.png");
const FrameIcon1 = parseUrl("/images/favorite-groups/Frame-icon(1).png");
const FrameIcon2 = parseUrl("/images/favorite-groups/Frame-icon(2).png");
const FrameIcon3 = parseUrl("/images/favorite-groups/Frame-icon(3).png");
const FrameIcon4 = parseUrl("/images/favorite-groups/Frame-icon(4).png");
const FrameIcon = parseUrl("/images/favorite-groups/Frame-icon.png");
const paymentUpcoming = parseUrl("/images/payment-upcoming.png");

import filterImageIcon from "./icons/filterImage.svg";
import calendarImage from "./icons/calendarImage.svg";
import imdbIcon from "./icons/imdbIcon.svg";
import earthIcon from "./icons/erthIcon.svg";
import heartIcon from "./icons/heartIcon.svg";
import fillHeartIcon from "./icons/fillHeartIcon.svg";
import starIcon from "./icons/starIcon.svg";
import languageSymbol from "./icons/languageSymbol.svg";
import redFlegIcon from "./icons/redFlegIcon.svg";
import shareLinkIcon from "./icons/shareLinkIcon.svg";
import playIcon from "./icons/playIcon.svg";
import removeIcon from "./icons/removeIcon.svg";
import videoplay from "./icons/videoplay.svg";
import messages from "./icons/messages.svg";
import facebookimg from "./icons/facebookLogo.svg";
import messenger from "./icons/messenger.svg";
import whatsAppicon from "./icons/whatsapp.svg";
import twitterIcon from "./icons/twitterIcon.svg";

import creatorAvtar from "./images/avtars/AvtarExample1.png";
import superProducerAvtar from "./images/avtars/AvtarExample2.png";
import managerAvtar from "./images/avtars/AvtarExample3.png";
import clientAvtar from "./images/avtars/AvtarExample4.png";

export {
  ArriLogo,
  BankhouseIcon,
  Cencalbtn,
  CloseBtn,
  FileshareIcon,
  // favorite-groups
  FrameIcon,
  FrameIcon1,
  FrameIcon2,
  FrameIcon3,
  FrameIcon4,
  MortonLogo,
  RedLogo,
  SiteLogo,
  ZeissLogo,
  accounts1,
  accounts2,
  accounts3,
  adClients1,
  adClients2,
  adClients3,
  adClients4,
  adCreator1,
  adCreator3,
  adCreator4,
  adManager1,
  adManager2,
  adManager3,
  adManager4,
  adSuperProducer2,
  adSuperProducer3,
  adSuperProducer4,
  adSuperProducer5,
  apple,
  applyIcon,
  arrowIcon,
  benderless,
  blender,
  calendarImage,
  cameraImage,
  cameraImg,
  cameraSuitcaseIcon,
  careersImage1,
  careersImage2,
  ciara,
  ciaraLogo,
  clientProfile,
  closeAccountIcon,
  connectedLinkIcon,
  cover1,
  creativeCollaborationImg1,
  creativeCollaborationImg2,
  creativeCollaborationImg3,
  creativeCollaborationImg4,
  creativeCollaborationImg5,
  creativeCollaborationImg6,
  creativeCollaborationImg7,
  creativesProfile,
  creativesProfileImg,
  creatorOnBoardingAvatar,
  crush,
  crushLogo,
  customerSlider1,
  customerSlider2,
  customerSlider3,
  dollarIcon,
  dorIcon,
  editIcon,
  emailBoxIcon,
  emailIcon,
  errorIcon,
  eyesIcon,
  facebook,
  facebookIcon,
  facebookLogo,
  fileUploadIcon,
  footerLogo,
  gitlab,
  google,
  googleColored,
  haderbachLogo,
  hope,
  hypergong,
  instagram,
  instagramIcon,
  interstellar,
  iqvia,
  jumalio,
  jumalioLogo,
  landingBottom1,
  landingBottom2,
  landingBottom3,
  landingBottom4,
  landingBottom5,
  landingBottom6,
  landingLeft1,
  landingLeft2,
  landingLeft3,
  landingLeft4,
  landingPage1,
  landingPage2,
  landingPage3,
  landingPage4,
  landingPage5,
  landingPage6,
  landingPage7,
  landingPage8,
  landingPage9,
  landingPageVideo,
  landingRight1,
  landingRight2,
  landingRight3,
  landingRight4,
  landingTop1,
  landingTop2,
  landingTop3,
  linkedinIcon,
  loactionIcon,
  locationImg,
  lockIcon,
  logOutIcon,
  logo,
  logoBlack,
  logoBlue,
  loreal,
  memberProfileIcon,
  memberVideoIcon,
  messengerIcon,
  mobileIcon,
  music1,
  music2,
  music3,
  music4,
  music5,
  music7,
  notificationIcon,
  paymentUpcoming,
  plusAddIcon,
  plusIcon,
  profileIcon,
  projectcard,
  rollins,
  seeStoriesVideo,
  sendMsgIcon,
  shareIcon,
  spBodyImage,
  spCard1,
  languageIcon,
  spCard2,
  spCard3,
  spCard4,
  spCard5,
  spHeader1,
  spHeader2,
  spaceX,
  spaceXLogo,
  ssAwards,
  ssBanner,
  ssSlide1,
  ssSliderMobile,
  sshaderbach,
  suitcaseIcon,
  textLogo,
  textLogoBlack,
  textLogoBlue,
  tiktokIcon,
  toggle,
  twitter,
  uber,
  uberLogo,
  userIcon,
  vector1,
  vector2,
  vector3,
  vector4,
  vector5,
  vector6,
  vector7,
  videoBg,
  videoIcon,
  vimeoIcon,
  vision1,
  vision2,
  whatsNew1,
  whatsNew2,
  whatsNew3,
  wildDreams,
  wildDreamsLogo,
  wolfboy,
  youtube,
  filterImageIcon,
  imdbIcon,
  earthIcon,
  heartIcon,
  fillHeartIcon,
  starIcon,
  languageSymbol,
  redFlegIcon,
  shareLinkIcon,
  modelImg,
  videoshoot,
  videoeditor,
  services1,
  playIcon,
  removeIcon,
  videoplay,
  messages,
  facebookimg,
  messenger,
  whatsAppicon,
  twitterIcon,
  clientAvtar,
  creatorAvtar,
  superProducerAvtar,
  managerAvtar,
};
